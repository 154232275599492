// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .splash-screen {
  background-color: #f2f3f8;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
:host .splash-screen img {
  margin-left: calc(100vw - 100%);
  width: 90px;
  margin-bottom: 30px;
}
:host .splash-screen span {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}
:host .splash-screen ::ng-deep [role=progressbar] {
  margin-left: calc(100vw - 100%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
