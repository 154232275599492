import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class DateHelperService {
  constructor(private datePipe: DatePipe) { }

  transformDate(date: Date, format: string) {
    let formattedDate;
    
    if (date) {
      formattedDate = this.datePipe.transform(date, format);
    }
    return formattedDate ?? ''
  }
}


declare global {
  interface Date {
    lastDay(): Date;
    addDays(days: number): Date;
    addMonth(months?: number): Date;
  }
}

Date.prototype.lastDay = function (): Date {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonth = function (months: number = 1): Date {
  const date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
};

