import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConst } from 'src/app/views/shared/AppConst';
import { IApplicationGroup } from '../model/IApplicationGroup';
import { IPaginationResponse } from 'src/app/views/shared/IPaginationResponse';
import { Observable } from 'rxjs';
import { IApiResponse } from 'src/app/views/shared/IApiResponse';
import { IWorkflow } from '../../workflows/workflow/model/IWorkflow';
import { AppServiceBase } from 'src/app/views/shared/app-service-base';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGroupService extends AppServiceBase{

  baseUrl = AppConst.remoteServiceBaseUrl + 'ApplicationGroup';
  constructor(private httpClient: HttpClient, injector: Injector) {super(injector) }

  getApplicationGroupEntries(): Observable<IPaginationResponse<IApplicationGroup[]>> {
    return this.httpClient.get<IPaginationResponse<IApplicationGroup[]>>(this.baseUrl)
  }
  
  getApplicationGroupById(id: number): Observable<IApiResponse<IApplicationGroup>> {
    return this.httpClient.get<IApiResponse<IApplicationGroup>>(this.baseUrl + '/' + id)
  }
  getApplicationGroup(id: number): Observable<IApiResponse<IApplicationGroup>> {
    return this.httpClient.get<IApiResponse<IApplicationGroup>>(`${this.baseUrl}/${id}`)
}
getRecords(params: any): Observable<any> {
  return this.httpClient.get(this.baseUrl, { params: this.getfilterParams(params, this.dateHelperService.transformDate(params?.filterModel?.date?.dateFrom, 'MM/d/y'))})
}
addApplicationGroup(ApplicationGroup: IApplicationGroup): Observable<IApiResponse<IApplicationGroup>> {
  return this.httpClient.post<IApiResponse<IApplicationGroup>>(this.baseUrl, ApplicationGroup, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  });
}
updateApplicationGroup(JVModel: IApplicationGroup): Observable<IApiResponse<IApplicationGroup>> {
  return this.httpClient.put<IApiResponse<IApplicationGroup>>(this.baseUrl + `/${JVModel.id}`, JVModel, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  })
}
workflow(workflow: IWorkflow): Observable<any> {
  return this.httpClient.post<any>(this.baseUrl + '/workflow', workflow);
}
getRecordByYearMonth(startDate: any, endDate: any): Observable<any> {        
  return this.httpClient.get(AppConst.remoteServiceBaseUrl + "ApplicationGroup?startDate=" + startDate + '&endDate=' + endDate);
}
deleteApplicatinGroup(id : string[]):Observable<any>{
  return this.httpClient.delete(`${AppConst.remoteServiceBaseUrl + "ApplicationGroup"}`,{
    body : id
  })
}
getApplicationGroups(): Observable<IPaginationResponse<IApplicationGroup[]>> {
  return this.httpClient.get<IPaginationResponse<IApplicationGroup[]>>(this.baseUrl)
}
}
