import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppServiceBase } from 'src/app/views/shared/app-service-base';
import { AppConst } from 'src/app/views/shared/AppConst';
import { IApiResponse } from 'src/app/views/shared/IApiResponse';
import { IDuration } from '../model/IDuration';
import { IPaginationResponse } from 'src/app/views/shared/IPaginationResponse';
import { IWorkflow } from '../../purchase/vendorBill/model/IWorkflow';

@Injectable({
  providedIn: 'root'
})
export class DurationService extends AppServiceBase{

  baseUrl = AppConst.remoteServiceBaseUrl + 'Duration';
  constructor(private httpClient: HttpClient, injector: Injector) {super(injector) }

  getDurationEntries(): Observable<IPaginationResponse<IDuration[]>> {
    return this.httpClient.get<IPaginationResponse<IDuration[]>>(this.baseUrl)
  }
  
  getDurationById(id: number): Observable<IApiResponse<IDuration>> {
    return this.httpClient.get<IApiResponse<IDuration>>(this.baseUrl + '/' + id)
  }
  getDuration(id: number): Observable<IApiResponse<IDuration>> {
    return this.httpClient.get<IApiResponse<IDuration>>(`${this.baseUrl}/${id}`)
}
getRecords(params: any): Observable<any> {
  return this.httpClient.get(this.baseUrl, { params: this.getfilterParams(params, this.dateHelperService.transformDate(params?.filterModel?.date?.dateFrom, 'MM/d/y'))})
}
addDuration(Duration: IDuration): Observable<IApiResponse<IDuration>> {
  return this.httpClient.post<IApiResponse<IDuration>>(this.baseUrl, Duration, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  });
}
updateDuration(JVModel: IDuration): Observable<IApiResponse<IDuration>> {
  return this.httpClient.put<IApiResponse<IDuration>>(this.baseUrl + `/${JVModel.id}`, JVModel, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  })
}
workflow(workflow: IWorkflow): Observable<any> {
  return this.httpClient.post<any>(this.baseUrl + '/workflow', workflow);
}
getRecordByYearMonth(startDate: any, endDate: any): Observable<any> {        
  return this.httpClient.get(AppConst.remoteServiceBaseUrl + "Duration?startDate=" + startDate + '&endDate=' + endDate);
}
}
