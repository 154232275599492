// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-heading {
  margin-bottom: 0px !important;
  padding: 10px 10px 10px 20px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background-image: linear-gradient(135deg, #1a6b85 50%, #f2f2f2 0%);
  border-bottom: 2px solid #eee;
  height: 60px;
  align-items: center;
  display: flex;
}

.dialog-img {
  height: 70px;
  background-color: #dbebf9;
  padding: 6px 6px;
  border-radius: 80%;
}

.mat-mdc-dialog-content {
  padding: 20px;
}

.mat-mdc-dialog-actions {
  background-color: #f2f2f2;
  padding: 12px 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
