// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `kt-auth, #kt_login_wrapper {
  height: 100%;
}
@media (min-width: 992px) {
  kt-auth .login.login-3 .login-form, #kt_login_wrapper .login.login-3 .login-form {
    width: 100%;
    max-width: 450px;
  }
}
kt-auth .login-form, #kt_login_wrapper .login-form {
  width: 100%;
  max-width: 450px;
}
kt-auth .login-form .mat-mdc-form-field, #kt_login_wrapper .login-form .mat-mdc-form-field {
  width: 100%;
}

kt-auth, .login {
  height: 100%;
}
kt-auth .login-form, .login .login-form {
  width: 100%;
  max-width: 450px;
}
kt-auth .login-form .mat-mdc-form-field, .login .login-form .mat-mdc-form-field {
  width: 100%;
}
kt-auth .spinner, .login .spinner {
  padding-right: 3rem !important;
}

.height-100 {
  height: 100%;
}

.auth-bg-image {
  background-color: #eef0f8;
  background-size: cover;
}

/*------------------Login form left div styles start------------*/
.form-left-div-bg {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.form-left-div-bg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background-color: #141e27;
  border-radius: 5px;
}

.logo-img {
  width: 50%;
  padding-bottom: 20px;
}

.form-h1 {
  float: center;
}

/*------------------Login form left div styles end------------*/
/*------------------Login form right div styles start------------*/
.form-right-div-bg {
  background-color: #eef0f8;
  text-align: center;
  border-radius: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 60px;
}

.form-right-div-bg form {
  width: 100%;
  padding: 0px 20px;
}

/*------------------Login form right div styles end------------*/
@media (min-width: 320px) { /* smartphones, iPhone, portrait 480x320 phones */
  .form-h1 {
    font-size: 18px;
  }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
  .form-h1 {
    font-size: 18px;
  }
}
.error_msg {
  margin-top: 2px;
  padding: 2px 10px;
  position: absolute;
  top: 20%;
  right: 20px;
  font-size: 12px;
}

.error_msg i {
  font-size: 12px;
}

.error_msg_pass {
  margin-top: 2px;
  padding: 2px 10px;
  position: absolute;
  top: 20%;
  right: 20px;
  font-size: 12px;
}

.error_msg_pass i {
  font-size: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
