import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppServiceBase } from 'src/app/views/shared/app-service-base';
import { AppConst } from 'src/app/views/shared/AppConst';
import { IPaginationResponse } from 'src/app/views/shared/IPaginationResponse';
import { IApplicationUnit } from '../model/IApplicationUnit';
import { IApiResponse } from 'src/app/views/shared/IApiResponse';
import { IWorkflow } from '../../purchase/vendorBill/model/IWorkflow';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUnitService extends AppServiceBase {

  baseUrl = AppConst.remoteServiceBaseUrl + 'ApplicationUnit';

  constructor(private httpClient: HttpClient, injector: Injector) { super(injector)  }

  getApplicationUnitEntries(): Observable<IPaginationResponse<IApplicationUnit[]>> {
    return this.httpClient.get<IPaginationResponse<IApplicationUnit[]>>(this.baseUrl)
  }

  getApplicationUnitById(id: number): Observable<IApiResponse<IApplicationUnit>> {
    return this.httpClient.get<IApiResponse<IApplicationUnit>>(this.baseUrl + '/' + id)
  }
  getApplicationUnit(id: number): Observable<IApiResponse<IApplicationUnit>> {
    return this.httpClient.get<IApiResponse<IApplicationUnit>>(`${this.baseUrl}/${id}`)
}

getApplicationUnites(): Observable<IPaginationResponse<IApplicationUnit[]>> {
  return this.httpClient.get<IPaginationResponse<IApplicationUnit[]>>(this.baseUrl)
}
 addApplicationUnit(formData: FormData): Observable<IApiResponse<IApplicationUnit>> {
    return this.httpClient.post<IApiResponse<IApplicationUnit>>(this.baseUrl, formData, {
      headers: new HttpHeaders({

      })
    });
  }
  updateApplicationUnit(formData: FormData): Observable<IApiResponse<IApplicationUnit>> {
    return this.httpClient.put<IApiResponse<IApplicationUnit>>(this.baseUrl + `/${formData.get('id')}`, formData, {
      headers: new HttpHeaders({
           
      })
    });
  }
  updateLogo(id: number, formData: FormData): Observable<IApiResponse<boolean>> {
    return this.httpClient.put<IApiResponse<boolean>>(`${this.baseUrl}/${id}/logo`, formData);
  }

  uploadFile(id: number , file: File ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(`${this.baseUrl}/DocUpload/${id}`, formData)
  }

  workflow(workflow: IWorkflow): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/workflow', workflow);
  }

  getRecords(params: any): Observable<any> {
    return this.httpClient.get(this.baseUrl, { params: this.getfilterParams(params, this.dateHelperService.transformDate(params?.filterModel?.date?.dateFrom, 'MM/d/y'))})
  }

  getRecordByYearMonth(startDate: any, endDate: any): Observable<any> {        
    return this.httpClient.get(AppConst.remoteServiceBaseUrl + "ApplicationUnit?startDate=" + startDate + '&endDate=' + endDate);
  }
  deleteApplicatinUnit(id : string[]):Observable<any>{
    return this.httpClient.delete(`${AppConst.remoteServiceBaseUrl + "ApplicationUnit"}`,{
      body : id
    })
  }
}
