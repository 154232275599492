// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  margin: 0;
}

.my-overlay {
  left: 0 !important;
  top: 0 !important;
  z-index: 500 !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  background-color: rgba(255, 255, 255, 0.9294117647) !important;
  visibility: visible !important;
  transition: visibility 0s, opacity 0.2 linear !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait !important;
}

.loader-img {
  left: 47% !important;
  top: 37% !important;
  z-index: 98 !important;
  height: 100px !important;
  position: fixed !important;
  visibility: visible !important;
  transition: visibility 0s, opacity 0.2 linear !important;
  display: flex;
  align-items: center;
  justify-content: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
