import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppServiceBase } from 'src/app/views/shared/app-service-base';
import { AppConst } from 'src/app/views/shared/AppConst';
import { IPaginationResponse } from 'src/app/views/shared/IPaginationResponse';
import { IDiscount } from '../model/IDiscount';
import { IApiResponse } from 'src/app/views/shared/IApiResponse';
import { IWorkflow } from '../../purchase/vendorBill/model/IWorkflow';

@Injectable({
  providedIn: 'root'
})
export class DiscountService extends AppServiceBase{

  baseUrl = AppConst.remoteServiceBaseUrl + 'Discount';
  constructor(private httpClient: HttpClient, injector: Injector) {super(injector) }

  getDiscountEntries(): Observable<IPaginationResponse<IDiscount[]>> {
    return this.httpClient.get<IPaginationResponse<IDiscount[]>>(this.baseUrl)
  }
  getDiscountById(id: number): Observable<IApiResponse<IDiscount>> {
    return this.httpClient.get<IApiResponse<IDiscount>>(this.baseUrl + '/' + id)
  }
  getDiscount(id: number): Observable<IApiResponse<IDiscount>> {
    return this.httpClient.get<IApiResponse<IDiscount>>(`${this.baseUrl}/${id}`)
}
getRecords(params: any): Observable<any> {
  return this.httpClient.get(this.baseUrl, { params: this.getfilterParams(params, this.dateHelperService.transformDate(params?.filterModel?.date?.dateFrom, 'MM/d/y'))})
}
addDiscount(Discount: IDiscount): Observable<IApiResponse<IDiscount>> {
  return this.httpClient.post<IApiResponse<IDiscount>>(this.baseUrl, Discount, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  });
}
updateDiscount(JVModel: IDiscount): Observable<IApiResponse<IDiscount>> {
  return this.httpClient.put<IApiResponse<IDiscount>>(this.baseUrl + `/${JVModel.id}`, JVModel, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  })
}
workflow(workflow: IWorkflow): Observable<any> {
  return this.httpClient.post<any>(this.baseUrl + '/workflow', workflow);
}
getRecordByYearMonth(startDate: any, endDate: any): Observable<any> {        
  return this.httpClient.get(AppConst.remoteServiceBaseUrl + "Duration?startDate=" + startDate + '&endDate=' + endDate);
}
deleteDiscount(id : string[]):Observable<any>{
  return this.httpClient.delete(`${AppConst.remoteServiceBaseUrl + "Discount"}`,{
    body : id
  })
}
getDiscounts(): Observable<IPaginationResponse<IDiscount[]>> {
  return this.httpClient.get<IPaginationResponse<IDiscount[]>>(this.baseUrl)
}
}
