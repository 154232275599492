// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `kt-base {
  opacity: 0;
  height: 100%;
}
kt-base .grid-root {
  height: 100%;
}

.page-loaded kt-base {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
