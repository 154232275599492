// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .topbar > :last-child:not([role=tooltip]) .topbar-item {
  margin-right: 0 !important;
}
:host ::ng-deep .topbar .topbar-item {
  margin-right: 0.36rem !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
