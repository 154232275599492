// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
:host ::ng-deep .loading-bar .progress-bar {
  background-color: #5d78ff;
}
:host ::ng-deep .topbar-item {
  height: 100%;
}
@media (min-width: 1025px) {
  :host ::ng-deep .topbar, :host ::ng-deep .topbar-item-wrapper {
    height: 100%;
  }
}

.my-overlay {
  left: 0 !important;
  top: 0 !important;
  z-index: 10000 !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  background-color: rgba(77, 73, 73, 0.2) !important;
  cursor: pointer !important;
  visibility: visible !important;
  transition: visibility 0s, opacity 0.2 linear !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-img {
  left: 47% !important;
  top: 25% !important;
  z-index: 99 !important;
  width: 80px !important;
  height: 100px !important;
  position: fixed !important;
  visibility: visible !important;
  transition: visibility 0s, opacity 0.2 linear !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  margin-top: 1.5rem;
}

.nav-content {
  display: flex;
  justify-content: space-between;
}
.nav-content a {
  color: black;
  text-decoration: none;
  padding: 2px;
}
.nav-content i {
  color: black;
  font-size: 12px;
}

.nav-content > li {
  list-style: none;
  margin-left: 2rem;
  position: relative;
  color: black;
  cursor: pointer;
  padding: 2px 5px;
}
.nav-content > li:hover {
  background-color: #e3f2f3;
  border: 1px solid #017e84;
  border-radius: 3px;
}

.vendors_li:hover .VendorDropdown {
  display: block;
}

.account_li:hover .accountingDropdown {
  display: block;
}

.customers_li:hover .customerDropdown {
  display: block;
}

.report_li:hover .reportingDropdown {
  display: block;
}

.config_li:hover .configDropdown {
  display: block;
}

.customerDropdown, .VendorDropdown, .accountingDropdown, .configDropdown, .reportingDropdown {
  background-color: white;
  display: none;
  position: absolute;
  border: 1px solid lightgray;
  left: 0px;
  border-radius: 5px;
  top: 25px;
  padding: 5px;
  width: 170px;
}
.customerDropdown li, .VendorDropdown li, .accountingDropdown li, .configDropdown li, .reportingDropdown li {
  list-style: none;
}
.customerDropdown li:hover, .VendorDropdown li:hover, .accountingDropdown li:hover, .configDropdown li:hover, .reportingDropdown li:hover {
  background-color: rgb(243, 245, 247) !important;
}
.customerDropdown li:not(:first-child), .VendorDropdown li:not(:first-child), .accountingDropdown li:not(:first-child), .configDropdown li:not(:first-child), .reportingDropdown li:not(:first-child) {
  margin-top: 10px; /* Adjust the margin as needed */
}
.customerDropdown:hover, .VendorDropdown:hover, .accountingDropdown:hover, .configDropdown:hover, .reportingDropdown:hover {
  display: block;
}

.ag-theme-alpine .ag-header {
  position: sticky;
  top: 0;
  z-index: 10; /* Ensure it stays above other content */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
