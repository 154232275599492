// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  height: 70px;
  pointer-events: none;
  transition: opacity 0.2s;
  display: inline-block;
  margin-top: -2.5%;
  margin-left: 30px;
}

:host.ag-tooltip-hiding {
  opacity: 0;
}

.appearance {
  background-color: #217089;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: 400;
}

.appearance::after {
  content: "";
  position: absolute;
  top: 27%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #217089 transparent transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
